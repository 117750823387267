import {Option} from '@/components/select/select';

export const CANVAS_BALL_VARIANTS: Array<Option> = [
    { value: '#34568B', label: 'Classic Blue' },
    { value: '#FF6F61', label: 'Living Coral' },
    { value: '#6B5B95', label: 'Ultra Violet' },
    { value: '#88B04B', label: 'Greenery' },
    { value: '#F7CAC9', label: 'Rose Quartz' },
    { value: '#92A8D1', label: 'Serenity' },
    { value: '#955251', label: 'Marsala' },
    { value: '#B565A7', label: 'Radiand Orchid' },
    { value: '#009B77', label: 'Emerald' },
    { value: '#DD4124', label: 'Tangerine Tango' },
    { value: '#D65076', label: 'Honeysucle' },
    { value: '#45B8AC', label: 'Turquoise' },
    { value: '#EFC050', label: 'Mimosa' },
    { value: '#5B5EA6', label: 'Blue Izis' },
    { value: '#9B2335', label: 'Chili Pepper' },
    { value: '#DFCFBE', label: 'Sand Dollar' },
    { value: '#55B4B0', label: 'Blue Turquoise' },
    { value: '#E15D44', label: 'Tigerlily' },
    { value: '#7FCDCD', label: 'Aqua Sky' },
    { value: '#BC243C', label: 'True Red' },
    { value: '#C3447A', label: 'Fuchsia Rose' },
    { value: '#98B4D4', label: 'Cerulean Blue' }
];

export const NAVBAR_DARK_VARIANTS: Array<Option> = [
    {value: 'navbar-warning navbar-light', label: 'Warning' },
    {value: 'navbar-orange navbar-light', label: 'Orange' },
    {value: 'navbar-dark', label: 'Dark'},
    {value: 'navbar-primary navbar-dark', label: 'Primary'},
    {value: 'navbar-secondary navbar-dark', label: 'Secondary'},
    {value: 'navbar-info navbar-dark', label: 'Info'},
    {value: 'navbar-success navbar-dark', label: 'Success'},
    {value: 'navbar-danger navbar-dark', label: 'Danger'},
    {value: 'navbar-indigo navbar-dark', label: 'Indigo'},
    {value: 'navbar-purple navbar-dark', label: 'Purple'},
    {value: 'navbar-pink navbar-dark', label: 'Pink'},
    {value: 'navbar-navy navbar-dark', label: 'Navy'},
    {value: 'navbar-lightblue navbar-dark', label: 'Light Blue'},
    {value: 'navbar-teal navbar-dark', label: 'Teal'},
    {value: 'navbar-cyan navbar-dark', label: 'Cyan'},
    {value: 'navbar-gray-dark navbar-dark', label: 'Gray Dark'},
    {value: 'navbar-gray navbar-dark', label: 'Gray'}
];

export const SIDEBAR_SKINS: Array<Option> = [
    { value: 'sidebar-dark-primary', label: 'Primary' }, { value: 'sidebar-light-primary', label: 'Primary Light' },
    { value: 'sidebar-dark-warning', label: 'Warning' }, { value: 'sidebar-light-warning', label: 'Warning Light' },
    { value: 'sidebar-dark-info', label: 'Info' }, { value: 'sidebar-light-info', label: 'Info Light' },
    { value: 'sidebar-dark-danger', label: 'Danger' }, { value: 'sidebar-light-danger', label: 'Danger Light' },
    { value: 'sidebar-dark-success', label: 'Success' }, { value: 'sidebar-light-success', label: 'Success Light' },
    { value: 'sidebar-dark-indigo', label: 'Indigo' }, { value: 'sidebar-light-indigo', label: 'Indigo Light' },
    { value: 'sidebar-dark-lightblue', label: 'Lightblue' }, { value: 'sidebar-light-lightblue', label: 'Lightblue Light' },
    { value: 'sidebar-dark-navy', label: 'Navy' }, { value: 'sidebar-light-navy', label: 'Navy Light' },
    { value: 'sidebar-dark-purple', label: 'Purple' }, { value: 'sidebar-light-purple', label: 'Purple Light' },
    { value: 'sidebar-dark-fuchsia', label: 'Fuchsia' }, { value: 'sidebar-light-fuchsia', label: 'Fuchsia Light' },
    { value: 'sidebar-dark-pink', label: 'Pink' }, { value: 'sidebar-light-pink', label: 'Pink Light' },
    { value: 'sidebar-dark-maroon', label: 'Maroon' }, { value: 'sidebar-light-maroon', label: 'Maroon Light' },
    { value: 'sidebar-dark-orange', label: 'Orange' }, { value: 'sidebar-light-orange', label: 'Orange Light' },
    { value: 'sidebar-dark-lime', label: 'Lime' }, { value: 'sidebar-light-lime', label: 'Lime Light' },
    { value: 'sidebar-dark-teal', label: 'Teal' }, { value: 'sidebar-light-teal', label: 'Teal Light' },
    { value: 'sidebar-dark-olive', label: 'Olive' }, { value: 'sidebar-light-olive', label: 'Olive Light' }
];

