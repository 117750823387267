import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-2" }
const _hoisted_4 = { class: "col-sm-4" }
const _hoisted_5 = { class: "col-sm-4 text-center" }
const _hoisted_6 = { class: "col-sm-4" }
const _hoisted_7 = { class: "breadcrumb float-sm-right" }
const _hoisted_8 = { class: "breadcrumb-item" }
const _hoisted_9 = { class: "breadcrumb-item active" }
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "container-fluid" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-md-12" }
const _hoisted_14 = { class: "card card-primary" }
const _hoisted_15 = {
  id: "fullCalendar",
  class: "card-body p-0"
}

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_FullCalendar = _resolveComponent("FullCalendar")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t(_ctx.calendarName)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.createCalendar())),
              class: "btn btn-primary btn-flat"
            }, _toDisplayString(_ctx.$t("labels.newCalendar")), 1),
            (_ctx.calendarName != 'Default')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.deleteCalendar())),
                  class: "btn btn-primary btn-flat ml-2"
                }, _toDisplayString(_ctx.$t("labels.deleteCalendar")), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ol", _hoisted_7, [
              _createElementVNode("li", _hoisted_8, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("labels.home")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.$t(_ctx.calendarName)), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_FullCalendar, {
                  id: "calendar",
                  ref: "calendar",
                  options: _ctx.calendarOptions,
                  contenteditable: "true"
                }, null, 8, ["options"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}