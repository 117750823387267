import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-2" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "breadcrumb float-sm-right" }
const _hoisted_7 = { class: "breadcrumb-item" }
const _hoisted_8 = { class: "breadcrumb-item active" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = { class: "container-fluid" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-header" }
const _hoisted_13 = {
  class: "card-title",
  style: {"display":"contents"}
}
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { id: "summernoteCz" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { id: "summernoteEn" }
const _hoisted_19 = { class: "card-footer" }
const _hoisted_20 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_select = _resolveComponent("app-select")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("labels.contentEditor")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ol", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("labels.home")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.$t("labels.contentEditor")), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("h3", _hoisted_13, [
              _createTextVNode(_toDisplayString(_ctx.$t("labels.selectMenu")) + ": ", 1),
              _createElementVNode("span", null, [
                _createVNode(_component_app_select, {
                  class: "mt-3",
                  modelValue: _ctx.selectedMenu,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedMenu) = $event)),
                  options: _ctx.menuList,
                  type: "custom",
                  onChange: _ctx.loadMenu
                }, null, 8, ["modelValue", "options", "onChange"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("languages.czech")), 1),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.contentCZ), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("languages.english")), 1),
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.contentEN), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.save())),
              disabled: (!_ctx.saveEnabled),
              class: "btn btn-primary btn-flat"
            }, _toDisplayString(_ctx.$t("labels.save")), 9, _hoisted_20)
          ])
        ])
      ])
    ])
  ], 64))
}